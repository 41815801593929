<template>
    <div>
        <div class="d-flex flex-column align-items-center bg-green rounded p-5">
            <span :class="`rounded-circle p-3 text-white bg-white`">
                <b-icon class="d-flex text-purple" icon="person-fill" font-scale="3"></b-icon>
            </span>
            <div class="text-white fs-4 mb-2">Novo Supervisor</div>
            <b-button variant="light" class="text-purple px-4" v-b-modal.modalSupervisorSave>Criar</b-button>
        </div>
        <div class="mt-4 bg-white rounded text-purple">
            <div class="d-flex justify-content-between align-items-center border-bottom p-3">
                <div class="fs-5 fw-bold">Supervisores</div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Supervisores</div>
                    <div class="fs-5 bg-purple text-white rounded-circle mx-3 px-2">{{ showManagers().length || 0 }}</div>
                    <b-form-input type="text" placeholder="Busca por Nome Supervisor" @input="filterManager"></b-form-input>
                </div>
            </div>
            <div v-if="showManagers().length">
                <b-row class="m-0 px-3 py-2">
                    <b-col class="p-0" cols="2">
                        <div class="fw-bold">Nome</div>
                    </b-col>
                    <b-col class="p-0 pe-1">
                        <div class="fw-bold">Permissões</div>
                    </b-col>
                    <b-col class="p-0" cols="3">
                        <div class="fw-bold">Departamentos</div>
                    </b-col>
                    <b-col class="p-0" cols="2"></b-col>
                </b-row>
                <b-row class="m-0 px-3 py-3 border-top d-flex align-items-center Supervisors" v-for="item in showManagers()" :key="item.id">
                    <b-col class="p-0" cols="2">
                        <div class="text-truncate">{{item.name}}</div>
                    </b-col>
                    <b-col class="p-0 pe-1">
                        <div class="d-inline" v-for="(permission, i) in item.permissions" :key="permission.id">   
                            <div class="d-inline-flex me-1" v-if="formatPermission(permission)">
                                {{ formatPermission(permission) }}<span v-if="i+1<item.permissions.length">,</span> 
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0" cols="3">
                        <span v-if="item.departments?.length && item.departments.at(0) == 'all'">
                            Todos
                        </span>
                        <span v-else>
                            <div class="d-inline" v-for="(dep, i) in item.departments" :key="dep.id">    
                                <div class="d-inline-flex me-1">
                                    {{ depName(dep) || '- - - -' }}<span v-if="i+1<item.departments.length">,</span> 
                                </div>
                            </div>
                        </span>
                    </b-col>
                    <b-col class="p-0 d-flex justify-content-end" cols="2">
                        <b-button variant="green" class="text-white manage-btn ms-0" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Editar" @click.prevent="selectUser(item)" v-b-modal.modalSupervisorSave>
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                        <b-button variant="red" class="text-white manage-btn me-0" v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover" @click.prevent="selectUser(item)" v-b-modal.modalSupervisorDelete>
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="p-3 text-purple text-center" v-else>
                Nenhum supervisor encontrado!
            </div>
        </div>
        <b-modal id="modalSupervisorSave" ref="modalSupervisorSave" :title="arrayAux._id ? 'Editar Supervisor' : 'Criar Supervisor'" hide-footer header-class="p-0 px-3">
            <b-form @submit.prevent="saveManager">
                <div>
                    <b-row class="m-0">
                        <b-col class="p-0">
                            <b-form-group label="Nome:" label-class="small-text fw-normal text-secondary">
                                <b-form-input v-model="arrayAux.name" required></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="m-0 mt-2">
                        <b-col class="p-0">
                            <b-form-group label="Login:" label-class="small-text fw-normal text-secondary">
                                <b-form-input v-model="arrayAux.login" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col class="p-0 ps-2">
                            <b-form-group label="Senha:" label-class="small-text fw-normal text-secondary">
                                <b-form-input type="password" v-model="arrayAux.password" :required="!arrayAux._id"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="mt-3">
                    <div class="text-secondary fw-semibold fs-5 border-bottom border-top py-2">Permissões</div>
                    <div class="mt-2">
                        <b-form-checkbox-group v-model="arrayAux.permissions">
                            <b-form-checkbox class="d-flex gap-2 text-secondary" value="AttendancesManage">
                                Atendimentos
                            </b-form-checkbox>
                            <b-form-checkbox class="d-flex gap-2 text-secondary" value="Manage" @change="manageChange">
                                Gerenciar
                            </b-form-checkbox>
                            <b-form-checkbox-group class="ms-4" v-model="arrayAux.permissions" v-if="arrayAux.permissions && includes(arrayAux.permissions,'Manage')">
                                <b-form-checkbox class="d-flex gap-2 text-secondary" value="ManageCampaigns" v-if="user.channel?.apiType == 'cloud' || user.channel?.apiType == 'gupshup'">
                                    Campanhas
                                </b-form-checkbox>
                                <b-form-checkbox value="ManageChannel">
                                    <div class="text-secondary ms-2">
                                        Canal
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="Managemenu" v-if="!user.channelConfig?.operationalChannelEnabled || includes(arrayAux.permissions,'Managemenu')">
                                    <div class="text-secondary ms-2">
                                        ChatBot
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="ManageDepartments">
                                    <div class="text-secondary ms-2">
                                        Departamentos
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="Managetags">
                                    <div class="text-secondary ms-2">
                                        Etiquetas
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="ManageQuickAnswers" v-if="user.channelConfig?.modules?.quickAnswers">
                                    <div class="text-secondary ms-2">
                                        Respostas Rápidas
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="ManageHSMModels" v-if="user.channel?.apiType == 'cloud' || user.channel?.apiType == 'gupshup'">
                                    <div class="text-secondary ms-2">
                                        Templates HSM
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="Managemessages">
                                    <div class="text-secondary ms-2">
                                        Mensagens
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="Manageoperators">
                                    <div class="text-secondary ms-2">
                                        Operadores
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="ManageTabulations">
                                    <div class="text-secondary ms-2">
                                        Tabulações
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox value="ManageKanbans">
                                    <div class="text-secondary ms-2">
                                        Kanbans
                                    </div>
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                            <b-form-checkbox class="d-flex gap-2 text-secondary" value="Contacts">
                                Contatos
                            </b-form-checkbox>
                            <b-form-checkbox class="d-flex gap-2 text-secondary" value="Reports" @change="reportsChange">
                                Relatórios
                            </b-form-checkbox>
                            <b-form-checkbox-group class="ms-4" v-model="arrayAux.permissions" v-if="arrayAux.permissions && includes(arrayAux.permissions, 'Reports')">
                                <b-form-checkbox class="d-flex gap-2 text-secondary" value="ReportGupshupTraffic" v-if="user.channel?.apiType === 'gupshup'">
                                    Relatório de Trafego
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                            <b-form-checkbox class="d-flex gap-2 text-secondary" value="Groups" v-if="user.channelConfig?.enableGroup">
                                Grupos
                            </b-form-checkbox>
                            <b-form-checkbox class="d-flex gap-2 text-secondary" value="API" v-if="user.channelConfig?.enableAPI">
                                API
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="text-secondary fw-semibold fs-5 border-bottom border-top py-2">Departamentos</div>
                    <div class="mt-3">
                        <div class="position-relative">
                            <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class="mt-2 position-absolute bg-white border w-100 rounded overflow-y-auto" style="z-index: 1; bottom: 2.5em; max-height: 70vh">
                                <b-form-checkbox-group
                                v-model="arrayAux.departments"
                                @change="verifyDepartments"
                                style="z-index: 1"
                                >
                                    <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" value="all" @change="verifyAllChecked">
                                        <div class="ms-1" role="button">Todos</div>
                                    </b-form-checkbox>
                                    <div v-for="item in departments" :key="item._id">
                                        <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item._id">
                                            <div class="ms-1" role="button">{{ item.name }}</div>
                                        </b-form-checkbox>
                                    </div>
                                </b-form-checkbox-group>
                                <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect>
                                </div>
                            </b-collapse>
                            <div class="border rounded w-100 text-secondary py-1 px-3 d-flex justify-content-between" style="border-color: #ced4da !important" role="button" v-b-toggle.collapseDepSelect>
                                <span v-if="arrayAux.departments?.length">
                                    <span v-if="arrayAux.departments.at(0) == 'all'">
                                        Todos
                                    </span>
                                    <span v-else>
                                        <span v-for="(dep,i) in arrayAux.departments" :key="dep._id">
                                            <span>
                                                {{ depName(dep) }}<span v-if="i+1 < arrayAux.departments.length">,</span> 
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span v-else>
                                    &nbsp;
                                </span>
                                <span>
                                    <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                    <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalSupervisorSave')">Cancelar</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="modalSupervisorDelete" ref="modalSupervisorDelete" title="Remover Supervisor" hide-footer size="sm" header-class="border-0 p-0 px-3" body-class="p-0">
            <div class="border-bottom border-top text-secondary smaller-text p-3">
                Tem certeza de que deseja remover este Supervisor?
            </div>
            <div class="p-3">
                <b-button type="submit" class="modal-btn modal-btnsearch" @click="deleteManager()">Remover</b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalSupervisorDelete')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'user'
    ],
    mounted: function() {
        this.$nextTick(function() {
            this.onCloseModal()
            this.getManagers()
            this.getDepartments()
        })
    },
    data() {
        return {
            managers: [],
            departments: [],
            filter: '',
            arrayAux: { role: 'channel_manager', channelId: this.user.channelId || this.user.roleId, departments: [] },
            userSelected: null
        }
    },
    methods: {
        showManagers() {
            return this.managers.filter(el => el.name.toLowerCase().includes(this.filter))
        },
        filterManager(e) {
            this.filter = e
        },
        async getManagers() {
            const resp = await api.getManagers(this.user.channelId || this.user.roleId)
            if (resp.statusCode !== 200) {
                this.managers = []
                return
            }

            this.managers = resp.managers.map(manager => {
                if (manager.permissions)
                    manager.permissions.sort((a, b) => (a && a.localeCompare(b)) || -1)
                return manager
            })
        },
        async getDepartments(){
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode!=200){
                this.departments = []
                this.departments['total'] = 0
                return
            }
            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        depName(_id) { // returns the name of the given _id department
            if(this.departments.length){
                const dep = this.departments.find(el=>el._id == _id)
                if(dep)
                    return dep.name
            }
        },
        verifyDepartments(e) {
            if(e.includes("all")) {
                if(e.at(-1) != "all") {
                    this.arrayAux.departments = this.arrayAux.departments.filter(e => e != "all")
                } else {
                    this.arrayAux.departments = ["all"]
                    this.departments.forEach(element => {
                        this.arrayAux.departments.push(element._id)
                    })
                }
            }
        },
        verifyAllChecked(e) {
            if(!e.includes("all")) {
                this.arrayAux.departments = []
            }
        },
        async saveManager() {
            this.arrayAux.role = 'channel_manager'
            let isNew,
                resp

            if(this.arrayAux.permissions?.at(0) == '') {
                this.arrayAux.permissions.shift()
                if(!this.arrayAux.permissions.length) {
                    this.arrayAux.permissions = [null]
                }
            }

            if(this.arrayAux.departments?.includes('all') && this.arrayAux.departments.length > 1) {
                this.arrayAux.departments = ['all' ]
            }

            if(!this.arrayAux._id) {
                isNew = true
            }

            if(isNew) {
                this.arrayAux.channelId = this.user.channelId || this.user.roleId
                resp = await api.createUser(this.arrayAux)
            } else {
                if((this.arrayAux.login != this.userSelected.login) || this.arrayAux.password) {
                    const userEdit = await api.updateUser(this.arrayAux)
                    console.log('userEdit',userEdit)
                    if(userEdit.statusCode == 409) {
                        return this.$emit('msg', {
                            text: `O login digitado já existe!`,
                            success: false
                        })
                    }
                }
                resp = await api.updateManager(this.arrayAux)
            }
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                this.$emit('msg', {
                    text: `Supervisor ${isNew ? 'criado' : 'editado'} com sucesso`,
                    success: true
                })

                this.arrayAux = { role:'channel_manager', channelId: this.user.channelId || this.user.roleId, departments: [] }
                if(this.$refs['modalSupervisorSave'])
                    this.$refs['modalSupervisorSave'].hide()
            } else if(resp.statusCode == 409) {
                this.$emit('msg', {
                    text: `O login digitado já existe!`,
                    success: false
                })
            } else { 
                this.$emit('msg', {
                    text:'Ocorreu um erro, revise os dados e tente novamente!',
                    success: false
                })
            }
            
            this.getManagers()
        },
        async deleteManager() {
            const resp = await api.deleteManager(this.userSelected._id)

            if(resp.error || resp.statusCode != 200) {
                this.$emit('msg', {
                    text:'Ocorreu um erro!',
                    success: false
                })
            } else {
                this.$emit('msg', {
                    text: "Supervisor removido com sucesso!",
                    success: true
                })
            }

            this.$refs['modalSupervisorDelete'].hide()
            this.getManagers()
        },
        async selectUser(item) {
            // console.log('item',item)
            const resp = await api.getManager(item._id)
            // console.log('resp getManager',resp)
            if(!resp.manager && resp.statusCode != 200) {
                if(!item.departments)
                    item.departments = []
                else if(this.departments?.length && item.departments.length && item.departments.at(0) == 'all') {
                    for (const dep of this.departments) {
                        item.departments.push(dep._id)
                    }
                }
                this.arrayAux = Object.assign({}, item);
                this.userSelected = item;
            } else {
                resp.manager.manager.login = resp.manager.user.login
                if(!resp.manager.manager.departments)
                    resp.manager.manager.departments = []
                else if(this.departments?.length && resp.manager.manager.departments.length && resp.manager.manager.departments.at(0) == 'all') {
                    for (const dep of this.departments) {
                        resp.manager.manager.departments.push(dep._id)
                    }
                }
                this.arrayAux = Object.assign({}, resp.manager.manager);
                this.userSelected = resp.manager.manager;
            }
        },
        includes(array,item) {
            return array.includes(item)
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.userSelected = null;
                this.arrayAux = { role:'channel_manager', channelId: this.user.channelId || this.user.roleId, departments: [] };
            })
        },
        reportsChange(e) {
            if(e.includes('Reports')) {
                e.push(...['ReportTabulation', 'ReportAttendance', 'ReportAverage'].filter(perm => !e.includes(perm)));
            } else {
                e = e.filter(perm => !['ReportTabulation', 'ReportAttendance', 'ReportAverage', 'ReportGupshupTraffic'].includes(perm));
            }
            if(!e.length) {
                this.arrayAux.permissions = [null]
                this.userSelected.permissions = [null]
            }
        },
        manageChange(e) {
            if(!e.includes('Manage')) {
                for (let i = e.length - 1; i >= 0; i -= 1) {
                    if (e[i]?.startsWith('Manage')) {
                        e.splice(i, 1);
                    }
                }
            }
            if(!e.length) {
                if(this.arrayAux)
                    this.arrayAux.permissions = [null]
                if(this.userSelected)
                    this.userSelected.permissions = [null]
            }
        },
        formatPermission(p) {
            switch(p) {
                case 'Reports':
                    return 'Relatórios'
                case 'ReportTabulation':
                    return null
                case 'ReportAttendance':
                    return null
                case 'ReportAverage':
                    return null
                case 'ReportGupshupTraffic':
                    return "Relatório de Tráfego"
                case 'Contacts':
                    return 'Contatos'
                case 'AttendancesManage':
                    return 'Atendimentos'
                case 'Manage':
                    return 'Gerenciar'
                case 'ManageTabulations':
                    return 'Gerenciar Tabulações'
                case 'ManageKanbans':
                    return 'Gerenciar Kanbans'
                case 'Manageoperators':
                    return 'Gerenciar Operadores'
                case 'Managemessages':
                    return 'Gerenciar Mensagens'
                case 'ManageDepartments':
                    return 'Gerenciar Departamentos'
                case 'ManageHSMModels':
                    return 'Gerenciar Templates HSM'
                case 'ManageChannel':
                    return 'Gerenciar Canal'
                case 'ManageCampaigns':
                    return 'Gerenciar Campanhas'
                case 'ManageChatbot':
                    return 'Gerenciar ChatBot'
                case 'Managemenu':
                    return 'Gerenciar ChatBot'
                case 'Managetags':
                    return 'Gerenciar Etiquetas'
                case 'ManageQuickAnswers':
                    return 'Gerenciar Respostas Rápidas'
                case 'Groups':
                    return 'Grupos'
                case 'API':
                    return 'API'
                default:
                    return p
            }
        }
    }
}
</script>